<template>
  <div>
    <Navbar />
    <div>
      <div class="home-body">
        <HomeSlider />
        <HomeNavigation />
        <div class="trending-box py-5">
          <div class="columns mr-0">
            <div class="column has-text-centered">
              <p class="text-heading bold">
                Trending
              </p>
              <RecentSearches />
              <div v-if="tileObjects" class="p-5">
                <div class="columns">
                  <div class="column">
                    <ContentCard
                      :title="tileObjects[0].title"
                      :subtitle="tileObjects[0].subtitle"
                      :link="tileObjects[0].weblink"
                      :image="tileObjects[0].coverPhoto"
                    />
                  </div>
                  <div class="column">
                    <ContentCard
                      :title="tileObjects[1].title"
                      :subtitle="tileObjects[1].subtitle"
                      :link="tileObjects[1].weblink"
                      :image="tileObjects[1].coverPhoto"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <ContentCard
                      :title="tileObjects[2].title"
                      :subtitle="tileObjects[2].subtitle"
                      :link="tileObjects[2].weblink"
                      :image="tileObjects[2].coverPhoto"
                    />
                  </div>
                  <div class="column">
                    <ContentCard
                      :title="tileObjects[3].title"
                      :subtitle="tileObjects[3].subtitle"
                      :link="tileObjects[3].weblink"
                      :image="tileObjects[3].coverPhoto"
                    />
                  </div>
                  <div class="column">
                    <ContentCard
                      :title="tileObjects[4].title"
                      :subtitle="tileObjects[4].subtitle"
                      :link="tileObjects[4].weblink"
                      :image="tileObjects[4].coverPhoto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PhotoGridGallery />    
      </div>
    </div>
    <Footer />    
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import HomeSlider from "@/components/app/home/HomeSlider";
import HomeNavigation from "@/components/app/home/HomeNavigation";
import RecentSearches from "@/components/app/home/RecentSearches";
import ContentCard from "@/components/app/home/ContentCard";
import PhotoGridGallery from "@/components/app/home/PhotoGridGallery";
import { orderBy } from "lodash";


export default {
  name: "Home",
  components: {
    Navbar,
    Footer,
    HomeSlider,
    HomeNavigation,
    RecentSearches,
    ContentCard,
    PhotoGridGallery
  },
  data() {
    return { };
    
  },
  computed: {
    tileObjects() {
      if (this.$store.state.tileObjects) {
        return orderBy(this.$store.state.tileObjects, ["tileNo"], ["asc"]);
      }
      return undefined;
    }
  },
  created() {
  
    this.$store.dispatch("getTileObjects");    
  },
  methods: {}
   
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.trending-box {
  background-color: #f8f8f8;
}
</style>
