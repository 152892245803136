<template>
  <b-carousel
    v-if="sliderObjects"
    :autoplay="true"
    :pause-info="false"
    :pause-hover="true"
    :indicator="false"
    :interval="5000"
  >
    <b-carousel-item v-for="(carousel, i) in sliderObjects" :key="i">
      <a
        :href="
          sliderObjects && sliderObjects[i].weblink
            ? sliderObjects[i].weblink
            : '#'
        "
      >
        <section
          class="hero is-medium is-bold slider-image-container"
          :style="sliderImage(i + 1)"
        >
          <div
            :class="
              isPortrait ? 'slider-slide-body-portrait' : 'slider-slide-body'
            "
          >
            <div class=" slider-content-box" style="align-self:flex-end">
              <div>
                <p class="text-subtitle bold" style="font-size:32px">
                  {{
                    sliderObjects && sliderObjects[i].title
                      ? sliderObjects[i].title
                      : ""
                  }}
                </p>
                <p class="">
                  {{
                    sliderObjects && sliderObjects[i].subtitle
                      ? sliderObjects[i].subtitle
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </section>
      </a>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
import { orderBy } from "lodash";

export default {
  name: "HomeSlider",
  data() {
    return {};
  },
  computed: {
    isPortrait() {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    },
    sliderObjects() {
      if (this.$store.state.sliderObjects) {
        return orderBy(this.$store.state.sliderObjects, ["sliderNo"], ["asc"]);
      }
      return undefined;
    }
  },
  created() {
    this.$store.dispatch("getSliderObjects");
  },
  methods: {
    sliderImage(index) {
      if (this.sliderObjects && index > 0 && index < 6) {
        return (
          "background-image: url(" +
          this.sliderObjects[index - 1].coverPhoto +
          ")"
        );
      } else {
        return "background-color:#222c4a";
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.slider-content-box {
  background-color: rgba(0, 0, 0, 0.705);
  color: $white;
  border-radius: 0px;
  padding: 5px 15px 15px 15px;
  margin: 0px 5vw;
}

.slider-image-container {
  background-size: cover;
  background-position: center;
}

.slider-slide-body {
  padding: 60vh 3vw 3vw 3vw;
}

.slider-slide-body-portrait {
  padding: 20vh 1vw 1vw 1vw;
}
</style>
