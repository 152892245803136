<template>
  <div class="columns m-0">
    <div class="column">
      <p v-if="trendingObjects">
        Top Searches:
        <span v-for="(trendingObj, i) in trendingObjects" :key="i">
          <a
            class="mx-2"
            :href="
              '/search?searchQuery=' +
                trendingObj.title +
                '&category=' +
                trendingObj.resourceType +
                '&sort=publicationYear-desc'
            "
          >
            {{ trendingObj.title }}
          </a>
          <span v-if="i != trendingObjects.length - 1"> /</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentSearches",
  data() {
    return {};
  },
  computed: {
    trendingObjects() {
      return this.$store.state.trendingObjects;
    }
  },
  created() {
    this.$store.dispatch("getTrendingObjects");
  },
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
