<template>
  <div class="columns m-0 home-navigation-bar">
    <div class="column"></div>
    <div class="column is-narrow has-text-centered">
      <router-link
        :to="{
          name: 'Explore',
          query: {
            category: 'eBook'
          }
        }"
      >
        <a class="has-text-dark nav-text mx-5">
          Library Catalogue
        </a>
      </router-link>
    </div>
    <div class="column is-narrow has-text-centered">
      <router-link
        :to="{
          name: 'Explore',
          query: {
            category: 'image'
          }
        }"
      >
        <a class="has-text-dark nav-text mx-5">
          Digital Collections
        </a>
      </router-link>
    </div>
    <div class="column is-narrow has-text-centered">
      <router-link
        :to="{
          name: 'Researchers'
        }"
      >
        <a class="has-text-dark nav-text mx-5">
          Researchers
        </a>
      </router-link>
    </div>
    <div class="column is-narrow has-text-centered">
      <router-link
        :to="{
          name: 'About'
        }"
      >
        <a class="has-text-dark nav-text mx-5">
          About
        </a>
      </router-link>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
export default {
  name: "HomeSlider",
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.home-navigation-bar {
  padding: 2px 0px;
  border: 2px solid $gray100;
}

.nav-text {
  font-size: 20px;
}
</style>
