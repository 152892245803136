<template>
  <div>
    <a :href="link ? link : '#'">
      <div class="card">
        <div class="card-image" :style="cardImage"></div>
        <div class="card-content has-text-left">
          <p class="text-subtitle text-bold">
            {{ title }}
          </p>
          <p class="">
            {{ subtitle }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "HomeSlider",
  props: {
    title: String,
    subtitle: String,
    link: String,
    image: String
  },
  data() {
    return {};
  },
  computed: {
    cardImage() {
      if (this.image) {
        return "background-image: url(" + this.image + ")";
      } else {
        return "background-color:#222c4a";
      }
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.card-image {
  height: 30vh;
  background-size: cover;
  background-position: center;
}
</style>
