var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns m-0 home-navigation-bar"},[_c('div',{staticClass:"column"}),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'Explore',
        query: {
          category: 'eBook'
        }
      }}},[_c('a',{staticClass:"has-text-dark nav-text mx-5"},[_vm._v(" Library Catalogue ")])])],1),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'Explore',
        query: {
          category: 'image'
        }
      }}},[_c('a',{staticClass:"has-text-dark nav-text mx-5"},[_vm._v(" Digital Collections ")])])],1),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'Researchers'
      }}},[_c('a',{staticClass:"has-text-dark nav-text mx-5"},[_vm._v(" Researchers ")])])],1),_c('div',{staticClass:"column is-narrow has-text-centered"},[_c('router-link',{attrs:{"to":{
        name: 'About'
      }}},[_c('a',{staticClass:"has-text-dark nav-text mx-5"},[_vm._v(" About ")])])],1),_c('div',{staticClass:"column"})])
}
var staticRenderFns = []

export { render, staticRenderFns }