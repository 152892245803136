<template>
  <div v-if="activeGallery" class="photo-grid-container">
    <div class="has-text-centered">
      <p class="text-heading text-white bold">
        {{ activeGallery.title }}
      </p>
      <p class="text-white">{{ activeGallery.subtitle }}</p>
    </div>
    <div class="row">
      <div class="column-img">
        <img
          v-for="(image, index) in imageCol1"
          :key="index"
          :src="image.attachmentURL"
          style="width: 100%"
          @click="navigateImage(image._id)"
        />
      </div>
      <div class="column-img">
        <img
          v-for="(image, index) in imageCol2"
          :key="index"
          :src="image.attachmentURL"
          style="width: 100%"
          @click="navigateImage(image._id)"
        />
      </div>
      <div class="column-img">
        <img
          v-for="(image, index) in imageCol3"
          :key="index"
          :src="image.attachmentURL"
          style="width: 100%"
          @click="navigateImage(image._id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../services/dataService";
import notificationService from "../../../services/notificationService";
export default {
  name: "PhotoGridGallery",
  data() {
    return {
      activeGallery: undefined,
      imageCol1: [],
      imageCol2: [],
      imageCol3: []
    };
  },
  computed: {},
  async created() {
    await this.getGallery();
  },
  methods: {
    navigateImage(id) {
      this.$router.push({
        name: "ItemDetail",
        query: { id, type: "image" }
      });
    },
    getGallery() {
      api
        .get("/gallery")
        .then(res => {
          this.activeGallery = res.data;
          for (let i = 0; i < res.data.images.length; i++) {
            const image = res.data.images[i];
            if (i % 3 == 0) {
              this.imageCol1.push(image);
            } else if (i % 3 == 1) {
              this.imageCol2.push(image);
            } else if (i % 3 == 2) {
              this.imageCol3.push(image);
            }
          }
        })
        .catch(err => notificationService.error(err.message[0]));
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.photo-grid-container {
  box-sizing: border-box;
  padding-bottom: 64px;
  padding-top: 32px;
  background: #16222a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    rgb(195, 40, 29),
    rgb(146, 31, 23)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    rgb(195, 40, 29),
    rgb(146, 31, 23)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

body {
  margin: 0;
  font-family: Arial;
}

.header {
  text-align: center;
  padding: 32px;
}

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column-img {
  -ms-flex: 33%; /* IE10 */
  flex: 33%;
  max-width: 33%;
  padding: 0 24px;
}

.column-img img {
  margin-top: 48px;
  vertical-align: middle;
  width: 100%;
  border: 8px solid $white;
  cursor: pointer;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 720px) {
  .column-img {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
</style>
